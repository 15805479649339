<template>
  <div id="modal-content-container" :class="[active_screen, { progress: isConfirmationInProgress }]">
    <div data-type="step-phone-input">
      <div class="modal-content-text">
        <span class="step-phone-input-common">Enter your mobile number, starting with your country code</span>
      </div>
      <div class="modal-content-input modal-content-item" :class="{'error' : !!mobile_number_error}">
        <div class="modal-content-item-label step-phone-input-common">
          <label for="modal-content-phone-input">Phone Number</label>
        </div>
        <div class="modal-content-item-wrapper wrapper-phone-number">
          <input
            id="modal-content-phone-input"
            v-model="mobile_number"
            type="text"
            @focus="mobile_number_error = null"
          >
        </div>
        <span class="modal-content-error">
          <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
            <circle fill="#ff6b6b"
                    fill-rule="evenodd"
                    cx="7"
                    cy="7"
                    r="7"
            />
            <path fill="#fff" fill-rule="evenodd" d="M6.535 9.061a11.426 11.426 0 0 1-.005-.29c0-.38.053-.707.16-.983.08-.208.207-.417.382-.628.13-.154.361-.379.696-.674.335-.296.552-.531.652-.707.1-.175.15-.367.15-.574 0-.376-.146-.707-.44-.991a1.492 1.492 0 0 0-1.08-.427c-.411 0-.755.129-1.03.386-.276.258-.457.661-.543 1.209l-.994-.118c.09-.734.356-1.297.798-1.687.442-.39 1.027-.585 1.754-.585.77 0 1.384.21 1.842.628.458.42.687.926.687 1.52 0 .344-.08.66-.241.95-.161.29-.476.644-.946 1.059-.315.28-.52.485-.617.618a1.377 1.377 0 0 0-.215.456c-.047.172-.073.451-.08.838h-.93zM6.476 11V9.899h1.101V11h-1.1z" />
          </svg>
          <span>{{ mobile_number_error }}</span>
        </span>
      </div>
      <div id="modal-content-recaptcha" />
      <div id="modal-content-recaptcha-error">
        {{ recaptcha_error }}
      </div>
      <div id="modal-content-sendsms">
        <Button
          type="green"
          value="Send SMS"
          :style="button_style"
          :class="{ 'disabled': !button_sms_enabled }"
          @click="sendSms"
        />
      </div>
    </div>

    <div data-type="step-code-input">
      <div class="modal-content-text">
        We’ve sent a verification code to <strong>{{ phone_number_full }}</strong>
      </div>
      <div class="modal-content-item" :class="{'error' : !!confirmation_code_error}">
        <input
          id="modal-content-verify-input"
          v-model="confirmation_code"
          type="text"
          placeholder="000000"
          @focus="confirmation_code_error = null"
        >
        <span class="modal-content-error">
          <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
            <circle fill="#ff6b6b"
                    fill-rule="evenodd"
                    cx="7"
                    cy="7"
                    r="7"
            />
            <path fill="#fff" fill-rule="evenodd" d="M6.535 9.061a11.426 11.426 0 0 1-.005-.29c0-.38.053-.707.16-.983.08-.208.207-.417.382-.628.13-.154.361-.379.696-.674.335-.296.552-.531.652-.707.1-.175.15-.367.15-.574 0-.376-.146-.707-.44-.991a1.492 1.492 0 0 0-1.08-.427c-.411 0-.755.129-1.03.386-.276.258-.457.661-.543 1.209l-.994-.118c.09-.734.356-1.297.798-1.687.442-.39 1.027-.585 1.754-.585.77 0 1.384.21 1.842.628.458.42.687.926.687 1.52 0 .344-.08.66-.241.95-.161.29-.476.644-.946 1.059-.315.28-.52.485-.617.618a1.377 1.377 0 0 0-.215.456c-.047.172-.073.451-.08.838h-.93zM6.476 11V9.899h1.101V11h-1.1z" />
          </svg>
          <span> {{ confirmation_code_error }} </span>
        </span>
      </div>
      <div id="modal-content-verify">
        <Button
          type="green"
          value="Verify"
          :style="button_style"
          :class="{ 'disabled': !button_verify_enabled }"
          @click="verifyCode"
        />
      </div>
      <div id="modal-content-resend" @click="resetVerificationModal">
        Resend verification code
      </div>
    </div>

    <div data-type="step-success">
      <div class="info-block">
        <svg width="70"
             height="70"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="#40bd5e" d="M35 67.058c17.741 0 32.123-14.328 32.123-32.002C67.123 17.382 52.741 3.054 35 3.054S2.877 17.382 2.877 35.056C2.877 52.73 17.259 67.058 35 67.058zm0 2.866c-19.33 0-35-15.61-35-34.868C0 15.799 15.67.188 35 .188s35 15.61 35 34.868c0 19.257-15.67 34.868-35 34.868z" />
          <path fill="#40bd5e" d="M52.914 23.31l2.354 2.345L31.73 49.103 17.609 37.379l2.354-2.345 11.768 9.38L52.914 23.31z" />
        </svg>
        <h1>Success!</h1>
        <div class="modal-content-text">
          Your phone number was successfully verified.
        </div>
        <div class="backup-code-container">
          <h2>Backup code</h2>
          <div id="modal-backup-code-number">
            {{ backup_code }}
          </div>
          <div>Please write it down to be able to reset your phone number.</div>
        </div>
      </div>
      <div id="modal-content-success-button" class="cf-button" onclick="TwoFA.success()">
        Ok
      </div>
    </div>

    <div data-type="step-error">
      <div class="info-block">
        <svg width="72"
             height="72"
             viewBox="0 0 72 72"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="36"
                  cy="36"
                  r="34.5"
                  stroke="#E54D00"
                  stroke-width="3"
          />
          <path fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.5607 24.4393C25.9749 23.8536 25.0251 23.8536 24.4393 24.4393C23.8536 25.0251 23.8536 25.9749 24.4393 26.5607L33.8787 36L24.4393 45.4393C23.8536 46.0251 23.8536 46.9749 24.4393 47.5607C25.0251 48.1464 25.9749 48.1464 26.5607 47.5607L36 38.1213L45.4393 47.5607C46.0251 48.1464 46.9749 48.1464 47.5607 47.5607C48.1464 46.9749 48.1464 46.0251 47.5607 45.4393L38.1213 36L47.5607 26.5607C48.1464 25.9749 48.1464 25.0251 47.5607 24.4393C46.9749 23.8536 46.0251 23.8536 45.4393 24.4393L36 33.8787L26.5607 24.4393Z"
                fill="#E54D00"
          />
        </svg>
        <h1>Sorry!</h1>
        <div class="modal-content-text">
          {{ error_message_text }}
        </div>
      </div>
      <div id="modal-content-tryagain" @click="resetVerificationModal">
        Try again
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import Button from '@/components/Button';
import {PhrasesTwoFA} from '@/phrases/twofa';

export default {
  name: "TwoFA",
  components: {
    Button
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: 'phone-input',
      mobile_number: '',
      confirmation_code: '',
      mobile_number_error: null,
      confirmation_code_error: null,
      button_style: {
        height: '50px',
        width: '100%',
      },
      recaptcha_verifier: null,
      recaptcha_solved: false,
      recaptcha_error: '',
      verification_result: null,
      phrases: PhrasesTwoFA,
      isConfirmationInProgress: false,
      error_message_text: 'Something went wrong',
      backup_code: ''
    }
  },
  computed: {
    active_screen() {
      return `step-${this.step}`
    },
    button_sms_enabled() {
      return this.mobile_number > 0
    },
    button_verify_enabled() {
      return this.confirmation_code > 0
    },
    phone_number_full() {
      let phone_number = this.mobile_number;

      if (!phone_number.startsWith('+')) {
        phone_number = '+' + phone_number;
      }
      return phone_number;
    }
  },
  mounted() {
    const self = this;
    this.mobile_number = '';
    this.recaptcha_verifier = new firebase.auth.RecaptchaVerifier('modal-content-recaptcha', {
      'callback' : function() {
        self.recaptcha_solved = true;
        self.recaptcha_error = '';
      },
      'expired-callback' : function() {
        self.recaptcha_solved = false;
      }
    });

    this.recaptcha_verifier.render();
  },
  methods: {
    resetVerificationModal() {
      this.mobile_number = '';
      this.confirmation_code = '';
      this.backup_code = '';
      this.step = 'phone-input';
    },
    commitError(error) {
      this.isConfirmationInProgress = false;
      if (error.code === 'close') {
        error.code = 'phone_confirmation_wasnt_complete';
      }

      if (!this.phrases.errors[error.code]) {
        error.code = 'something_went_wrong';
      }
      this.error_message_text = this.$helper.getPhraseByKey(this.phrases.errors, error.code);

      this.step = 'error';
      this.$emit('verification-failed', error);
    },
    commitSuccess() {
      this.isConfirmationInProgress = false;
      this.step = this.backup_code > 0 ? 'success backup' : 'success';
      this.$emit('verification-succeed');
      this.$store.commit('updateMobileVerificationStatus', true);

      if (this.options.callback) {
        this.options.callback();
      }
    },
    sendSms() {
      let self = this;

      if (!this.button_sms_enabled) {
        return;
      }

      if (!this.recaptcha_solved) {
        this.recaptcha_error = this.$helper.getPhraseByKey(this.phrases.errors, 'captcha_not_solved');
        return;
      }

      firebase.auth().signInWithPhoneNumber(this.phone_number_full, this.recaptcha_verifier)
        .then((result) => {
          self.verification_result = result;
          self.step = 'code-input'
        })
        .catch((error) => {
          switch (error.code) {
            case 'auth/invalid-phone-number':
              self.mobile_number_error = self.$helper.getPhraseByKey(self.phrases.errors, 'invalid_phone');
              break;
            default:
              self.commitError(error);
          }
        });
      this.recaptcha_solved = false;
    },
    verifyCode() {
      let self = this;

      if (!this.button_verify_enabled) {
        return;
      }

      this.isConfirmationInProgress = true;

      this.verification_result.confirm(this.confirmation_code)
        .then(() => {
          firebase.auth().currentUser.getIdToken(true)
              .then((idToken) => {
                  if (self.options.mode === 'tokenize' && self.options.callback) {
                      self.options.callback(idToken);
                  } else {
                      self.verifyToken(idToken);
                  }
              })
              .catch((error) => {
                self.commitError(error)
              })
        })
        .catch((error) => {
          this.isConfirmationInProgress = false;
          switch (error.code) {
            case 'auth/invalid-verification-code':
              self.confirmation_code_error = self.$helper.getPhraseByKey(self.phrases.errors, 'invalid_code');
              break;
            default:
              self.commitError(error);
          }
        })
    },
    verifyToken(idToken) {
      let self = this;
      axios.post(`${this.$external.url.profiles}/ajax/room/mobile_verify.php`, {
        csrf: self.$external.csrf,
        token: idToken
      })
        .then(response => {
          let data = response.data;
          if (data.error) {
            self.commitError({code : data.response ? data.response : 'phone_confirmation_wasnt_complete'});
          } else {
            self.backup_code = data.backup_code;
            self.commitSuccess();
          }
        })
        .catch(() => {
          self.commitError({code : 'phone_confirmation_wasnt_complete'});
        })
    }
  },
}
</script>

<style scoped>
#modal-content-container {
  padding: 30px 49px;
  max-width: 400px;
  box-sizing: border-box;
}
#modal-content-container.progress * {
  cursor: wait!important;
}
#modal-content-container > * {
  display: none;
}
#modal-content-container.step-success .backup-code-container {
  display: none;
  margin: 50px -35px 0;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 60px 12px;
  font-size: 14px;
}
#modal-content-container.step-success .backup-code-container > * {
  margin: 12px 0;
}
#modal-content-container.step-success .backup-code-container > h2 {
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  background-color: #ffffff;
  max-width: 120px;
  margin: -10px auto 12px;
}
#modal-backup-code-number {
  color: #40bd5e;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 1px;
}
#modal-content-container.step-phone-input > *[data-type="step-phone-input"],
#modal-content-container.step-code-input > *[data-type="step-code-input"],
#modal-content-container.step-success > *[data-type="step-success"],
#modal-content-container.step-error > *[data-type="step-error"],
#modal-content-container.step-success.backup .backup-code-container {
  display: block;
}

#modal-content-container.step-phone-input.backup .step-phone-input-common {
  display: none;
}

.modal-content-item-label {
  text-align: left;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 5px;
}

#modal-content-container h1 {
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #545454;
}
.modal-content-text {
  padding: 12px 0 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 40px;
}
#modal-content-container.step-success .modal-content-text, #modal-content-container.step-error .modal-content-text  {
  margin-bottom: 0;
}
.modal-content-item {
  position: relative;
  display: inline-block;
  height: 63px;
  width: 100%;
  box-sizing: border-box;
  vertical-align: top;
}
.modal-content-item-wrapper > input[type="text"] {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0 40px 0 10px;
  box-sizing: border-box;
  border: none;
  font-weight: 400;
  font-size: 14px;
  color: #545454;
  background-color: transparent;
  outline: none;
}
.modal-content-item-wrapper,
.modal-content-item > input[type="text"],
.modal-content-item > input[type="password"] {
  display: block;
  height: 39px;
  width: 100%;
  padding: 0 40px 0 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  color: #545454;
  transition: border-color .2s;
}
.modal-content-item-wrapper {
  padding: 0;
  font-size: 0;
}
.modal-content-input {
  margin-bottom: 16px;
}
.modal-content-item-wrapper.wrapper-phone-number:before {
  content: '+';
  display: inline-block;
  position: absolute;
  top: 20px;
  left: 11px;
  font-size: 14px;
  line-height: 37px;
  color: #b3b3b3;
}
.modal-content-item-wrapper > input[type="text"] {
  padding: 0 40px 0 24px;
}
.modal-content-item-wrapper > input[type="text"]:hover,
.modal-content-item > input[type="text"]:hover,
.modal-content-item > input[type="password"]:hover {
  border-color: #a5a5a5;
}
.modal-content-item-wrapper > input[type="text"]:focus,
.modal-content-item > input[type="text"]:focus,
.modal-content-item > input[type="password"]:focus {
  border-color: #2ecb4e;
}
.modal-content-item > input[type="text"][disabled] {
  border-color: #ccc;
  background-color: #e3e3e3;
  cursor: not-allowed;
}
#modal-content-verify-input {
  padding: 0 40px;
  text-align: center;
  letter-spacing: 8px;
}

.modal-content-error {
  position: absolute;
  display: inline-block;
  right: 0;
  margin-top: 7px;
  opacity: 0;
  visibility: hidden;
}
.modal-content-item.error > .modal-content-error,
input.error + .modal-content-error {
  opacity: 1;
  visibility: visible;
}
.modal-content-error > span {
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  color: #545454;
  background-color: #d8d8d8;
  line-height: 19px;
  text-align: right;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: .2s;
}
.modal-content-error > svg {
  position: absolute;
  top: -39px;
  right: 8px;
  padding: 5px;
}
.modal-content-error > svg:hover + span {
  opacity: 1;
  visibility: visible;
}
#modal-content-recaptcha {
  padding-top: 7px;
  min-height: 85px;
}
#modal-content-recaptcha-error {
  padding-bottom: 30px;
  color: #ff6b6b;
  font-size: 12px;
  text-align: left;
}
#modal-content-container > *[data-type="step-success"] > svg,
#modal-content-container > *[data-type="step-error"] > svg {
  margin-bottom: 16px;
}
#modal-content-resend,
#modal-content-tryagain {
  display: inline-block;
  margin-top: 21px;
  font-weight: 400;
  font-size: 12px;
  color: #3d95cc;
  line-height: 16px;
  cursor: pointer;
  transition: .2s;
}
#modal-content-resend:hover,
#modal-content-tryagain:hover {
  color: #3cafff;
}
#modal-content-resend:active,
#modal-content-tryagain:active {
  color: #0075c7;
}
#modal-content-success-button {
  display: none;
}
</style>
