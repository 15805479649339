import Vue from 'vue'

export const PhrasesTwoFA = new Vue({
    data: {
        errors: {
            invalid_phone: 'Invalid phone number',
            invalid_code: 'Invalid verification code',
            error: 'Please try again later.',
            captcha_not_solved: 'Please solve captcha first',
            phone_already_bound_to_another_user: "This phone number is already linked to another account.",
            phone_number_unique: "A mobile number can only be linked to one Camfrog account at a time",
            phone_number_backup_invalid: "Invalid backup code",
            phone_confirmation_wasnt_complete: "Phone number verification did not complete.",
            something_went_wrong: 'Something went wrong'
        },
    },
});
